import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(() => ({
  Container: {
    '& li': {
      height: '516px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  carouselFooter: {
    textAlign: 'center',
    height: '40px',
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
    paddingBottom: 0,
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      bottom: '20px'
    },
  },
  scrollLabel: {
    textAlign: 'center',
    height: '40px',
    position: 'fixed',
    bottom: '20px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    paddingBottom: 0,
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      bottom: '40px'
    },
  },
  arrow: {
    width: '14px',
    height: '8px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  carouselItem: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  slideTitle: {
    fontSize: '28px',
    lineHeight: '30.8px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '250px',
      margin: 'auto',
    },
  },
  mainHeader: {
    fontSize: '32px',
    fontWeight: 500,
    fontFamily: FONTS.Graphik.GraphikMedium,
    marginBottom: '20px',
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '250px',
      margin: 'auto',
    },
  },
  slideNumber: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '12px',
  },
  slideImage: {
    maxWidth: '250px',
    maxHeight: '250px',
  },
  button: {
    backgroundColor: '#BFD3D0',
    borderColor: '#BFD3D0',
    border: '1px solid #000000 !important',
    maxHeight: '40px',
    maxWidth: '200px',
  },
  buttonText: {
    color: '#000000 !important',
    fontSize: '13px',
  },
  wrapper: {
    paddingLeft: '30px',
    paddingRight: '30px',
    textAlign: 'center',
  },
  slideText: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      padding: '0 15%',
    },
  },
  outerCircle: {
    width: '250px',
    height: '250px',
    borderRadius: '50%',
    backgroundColor: COLORS.whiteStain,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContent: {
    width: '100%',
    height: '89px',
    backgroundColor: COLORS.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  circleTitle: {
    fontSize: '21px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    marginBottom: '5px',
  },
  circleText: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '16px',
  },
  closeButton: {
    width: 8,
    height: 8,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    color: '#000000',
  },
  logo: {
    maxWidth: '38px',
    maxHeight: '40.2px',
  },
  video: {
    maxWidth: '250px',
    maxHeight: '250px',
    width: '100%',
  },
  firstSlideImage: {
    maxWidth: '250px',
    maxHeight: '250px',
    display: 'none !important',
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      display: 'block !important',
      margin: 'auto',
    },
  },
}))
